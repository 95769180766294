"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProvisionInputTemplateByProcessId = void 0;
const CONSTANTS = __importStar(require("../../../common/constants"));
const mehmetky_provision_input_1 = require("../../common/client_inputs/mehmetky_provision_input");
const CakeFleet_provision_inputs_1 = require("../../common/client_inputs/CakeFleet_provision_inputs");
const pies_data_router_provision_inputs_1 = require("../../common/client_inputs/pies_data_router_provision_inputs");
const StuBurgE2ETesting_provision_inputs_1 = require("../../common/client_inputs/StuBurgE2ETesting_provision_inputs");
const adta_provision_inputs_1 = require("../../common/client_inputs/adta_provision_inputs");
const ARTasrsWorkcell_provision_inputs_1 = require("../../common/client_inputs/ARTasrsWorkcell_provision_inputs");
const autopack_provision_inputs_1 = require("../../common/client_inputs/autopack_provision_inputs");
const autosal_provision_inputs_1 = require("../../common/client_inputs/autosal_provision_inputs");
const autoslam_provision_inputs_1 = require("../../common/client_inputs/autoslam_provision_inputs");
const bcp_provision_inputs_1 = require("../../common/client_inputs/bcp_provision_inputs");
const cls_argus_sensor_mgmt_provision_inputs_1 = require("../../common/client_inputs/cls_argus_sensor_mgmt_provision_inputs");
const edm_eps_provisioning_test_provision_inputs_1 = require("../../common/client_inputs/edm_eps_provisioning_test_provision_inputs");
const edm_test_fleet_provision_inputs_1 = require("../../common/client_inputs/edm_test_fleet_provision_inputs");
const ephiron_provision_inputs_1 = require("../../common/client_inputs/ephiron_provision_inputs");
const fc_games_provision_inputs_1 = require("../../common/client_inputs/fc_games_provision_inputs");
const fc_pimms_provision_inputs_1 = require("../../common/client_inputs/fc_pimms_provision_inputs");
const karman_provision_inputs_1 = require("../../common/client_inputs/karman_provision_inputs");
const mda_provision_inputs_1 = require("../../common/client_inputs/mda_provision_inputs");
const mech_provision_inputs_1 = require("../../common/client_inputs/mech_provision_inputs");
const mercury_provision_inputs_1 = require("../../common/client_inputs/mercury_provision_inputs");
const mod_sort_provision_inputs_1 = require("../../common/client_inputs/mod_sort_provision_inputs");
const ops_tech_edge_wgb_provision_inputs_1 = require("../../common/client_inputs/ops_tech_edge_wgb_provision_inputs");
const pasta_service_provision_inputs_1 = require("../../common/client_inputs/pasta_service_provision_inputs");
const pid_provision_inputs_1 = require("../../common/client_inputs/pid_provision_inputs");
const process_tech_provision_inputs_1 = require("../../common/client_inputs/process_tech_provision_inputs");
const raindrops_provision_inputs_1 = require("../../common/client_inputs/raindrops_provision_inputs");
const rses_provision_inputs_1 = require("../../common/client_inputs/rses_provision_inputs");
const template_test_client_inputs_1 = require("../../common/client_inputs/template_test_client_inputs");
const test_client_process_provision_inputs_1 = require("../../common/client_inputs/test_client_process_provision_inputs");
const test_uwc_fleet_provision_input_1 = require("../../common/client_inputs/test_uwc_fleet_provision_input");
const uwc_comet_fleet_provision_inputs_1 = require("../../common/client_inputs/uwc_comet_fleet_provision_inputs");
const uwc_gemini_test_fleet_1 = require("../../common/client_inputs/uwc_gemini_test_fleet");
const uwc_mec_kiosk_provision_inputs_1 = require("../../common/client_inputs/uwc_mec_kiosk_provision_inputs");
const uwc_pre_prod_fleet_provision_inputs_1 = require("../../common/client_inputs/uwc_pre_prod_fleet_provision_inputs");
const UwcSasHandoffTestFleet_provision_inputs_1 = require("../../common/client_inputs/UwcSasHandoffTestFleet_provision_inputs");
const wattdemo_provision_inputs_1 = require("../../common/client_inputs/wattdemo_provision_inputs");
const wigo_provision_inputs_1 = require("../../common/client_inputs/wigo_provision_inputs");
const wireless_bridge_fleet_provision_inputs_1 = require("../../common/client_inputs/wireless_bridge_fleet_provision_inputs");
const config_applier_provision_inputs_1 = require("../../common/client_inputs/config_applier_provision_inputs");
function getProvisionInputTemplateByProcessId(processId) {
    switch (processId) {
        case CONSTANTS.AUTO_SLAM:
            return autoslam_provision_inputs_1.autoslam_provision_inputs;
        case CONSTANTS.AUTO_PACK:
            return autopack_provision_inputs_1.autopack_provision_inputs;
        case CONSTANTS.EDM_TEST_FLEET:
            return edm_test_fleet_provision_inputs_1.edm_test_fleet_provision_inputs;
        case CONSTANTS.MDA:
            return mda_provision_inputs_1.mda_provision_inputs;
        case CONSTANTS.TEST_CLIENT_PROCESS:
            return test_client_process_provision_inputs_1.test_client_process_provision_inputs;
        case CONSTANTS.WATT_DEMO_WORKCELL:
            return wattdemo_provision_inputs_1.wattdemo_provision_inputs;
        case CONSTANTS.ADTA:
            return adta_provision_inputs_1.adta_provision_inputs;
        case CONSTANTS.RAINDROPS:
            return raindrops_provision_inputs_1.raindrops_provision_inputs;
        case CONSTANTS.MECH:
            return mech_provision_inputs_1.mech_provision_inputs;
        case CONSTANTS.KARMAN:
            return karman_provision_inputs_1.karman_provision_inputs;
        case CONSTANTS.PASTA_SERVICE:
            return pasta_service_provision_inputs_1.pasta_service_provision_inputs;
        case CONSTANTS.OPS_TECH_EDGE_WGB:
            return ops_tech_edge_wgb_provision_inputs_1.ops_tech_edge_wgb_provision_inputs;
        case CONSTANTS.CLS_ARGUS_SENSOR_MGMT:
            return cls_argus_sensor_mgmt_provision_inputs_1.cls_argus_sensor_mgmt_provision_inputs;
        case CONSTANTS.PID:
            return pid_provision_inputs_1.pid_provision_inputs;
        case CONSTANTS.MOD_SORT:
            return mod_sort_provision_inputs_1.mod_sort_provision_inputs;
        case CONSTANTS.WIGO:
            return wigo_provision_inputs_1.wigo_provision_inputs;
        case CONSTANTS.RSES:
            return rses_provision_inputs_1.rses_provision_inputs;
        case CONSTANTS.BCP:
            return bcp_provision_inputs_1.bcp_provision_inputs;
        case CONSTANTS.AUTO_SAL:
            return autosal_provision_inputs_1.autosal_provision_inputs;
        case CONSTANTS.PROCESS_TECH:
            return process_tech_provision_inputs_1.process_tech_provision_inputs;
        case CONSTANTS.FC_PIMMS:
            return fc_pimms_provision_inputs_1.fc_pimms_provision_inputs;
        case CONSTANTS.FC_GAMES:
            return fc_games_provision_inputs_1.fc_games_provision_inputs;
        case CONSTANTS.TEMPLATE_TEST_CLIENT:
            return template_test_client_inputs_1.template_test_client_inputs;
        case CONSTANTS.TEST_UWC_FLEET:
            return test_uwc_fleet_provision_input_1.test_uwc_fleet_provision_inputs;
        case CONSTANTS.MERCURY:
            return mercury_provision_inputs_1.mercury_provision_inputs;
        case CONSTANTS.EDM_EPS_PROVISIONING_TEST:
            return edm_eps_provisioning_test_provision_inputs_1.edm_eps_provisioning_test_provision_inputs;
        case CONSTANTS.UWC_COMET_FLEET:
            return uwc_comet_fleet_provision_inputs_1.uwc_comet_fleet_provision_inputs;
        case CONSTANTS.AR_TASRS_WORKCELL:
            return ARTasrsWorkcell_provision_inputs_1.ar_tasrs_workcell_provision_inputs;
        case CONSTANTS.CAKE_FLEET:
            return CakeFleet_provision_inputs_1.CakeFleet_provision_inputs;
        case CONSTANTS.StuBurg_E2E_Testing:
            return StuBurgE2ETesting_provision_inputs_1.StuBurgE2ETesting_provision_inputs;
        case CONSTANTS.UWC_SAS_HANDOFF_TEST_FLEET:
            return UwcSasHandoffTestFleet_provision_inputs_1.uwc_sas_handoff_test_fleet_provision_inputs;
        case CONSTANTS.UWC_MEC_KIOSK:
            return uwc_mec_kiosk_provision_inputs_1.uwc_mec_kiosk_provision_inputs;
        case CONSTANTS.UWC_PRE_PROD_FLEET:
            return uwc_pre_prod_fleet_provision_inputs_1.uwc_pre_prod_fleet_provision_inputs;
        case CONSTANTS.WIRELESS_BRIDGE_FLEET:
            return wireless_bridge_fleet_provision_inputs_1.wireless_bridge_fleet_provision_inputs;
        case CONSTANTS.PIES_DATA_ROUTER:
            return pies_data_router_provision_inputs_1.PiesDataRouter_provision_inputs;
        case CONSTANTS.UWC_GEMINI_TEST_FLEET:
            return uwc_gemini_test_fleet_1.uwc_gemini_provision_inputs;
        case CONSTANTS.EPIPHRON:
            return ephiron_provision_inputs_1.ephiron_provision_inputs;
        case CONSTANTS.MEHMETKY_TEST_FLEET:
            return mehmetky_provision_input_1.mehmetky_provision_inputs;
        case CONSTANTS.CONFIG_APPLIER_FLEET:
            return config_applier_provision_inputs_1.config_applier_provision_inputs;
        default:
            return [];
    }
}
exports.getProvisionInputTemplateByProcessId = getProvisionInputTemplateByProcessId;
