"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.config_applier_provision_inputs = void 0;
exports.config_applier_provision_inputs = [
    {
        component: 'field',
        label: 'Workcell Number',
        name: 'workcellNumber',
        description: 'Example: 101',
        regex: '^[0-9]{3}$',
        defaultValue: '',
    },
    {
        component: 'provision-input-group',
        label: 'Site',
        name: 'Site',
        description: 'Example: BFI1',
        regex: '^[A-Z0-9]+$',
        defaultValue: '',
    },
];
